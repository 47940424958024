.form-control {
    appearance: auto; 
}

.toggle-switch {
    input[type=checkbox]{
        height: 0;
        width: 0;
        // visibility: hidden;
        display: none;
    }
    
    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 50px;
        height: 25px;
        background: grey;
        display: block;
        border-radius: 25px;
        position: relative;
    }
    
    label:after {
        content: '';
        position: absolute;
        top: 2.5px;
        left: 3px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }
    
    input:checked + label {
        background: $primary;
    }
    
    input:checked + label:after {
        left: calc(100% - 2.5px);
        transform: translateX(-100%);
    }
    
    label:active:after {
        width: 45px;
    }
}