// Custom Variables

$yacht-content-padding: 2rem;

// Override Bootstrap Variables
// https://github.com/twbs/bootstrap/blob/v5.1.1/scss/_variables.scss
$primary: #F19F0E;
$secondary: #002C4A;
$blue: #1f6ea4;

$danger: #f83e3e;
$warning: #ec823b;

$font-size-base: .9rem;
$font-family-sans-serif: "Karla", Helvetica, Arial, sans-serif;

$body-bg: $secondary;
$body-color: #000;

$link-color: $body-bg;
$link-decoration: underline;
$link-hover-decoration: underline;
$link-hover-color: $primary;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 1.5;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.15;
$h6-font-size:                $font-size-base;

$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;
$border-radius-pill:          0;

$input-bg: #fff;
$input-border-color: #bbb;

$modal-inner-padding: 2rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px,
  xxl: 1500px
);

$box-shadow-lg: 0 1rem 3rem rgba(#000, .1);