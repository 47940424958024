body[id^="view-yacht-crew-member"], body[id^="view-crew-portal"] {

    .yacht-content {
        @media (max-width: 500px) {
            padding: $yacht-content-padding / 2 !important;
        }
    }

    .crew-member-header {
        background: $gray-300;
        margin-top: -$yacht-content-padding;
        margin-right: -$yacht-content-padding;
        margin-left: -$yacht-content-padding;
        padding: $yacht-content-padding $yacht-content-padding ($yacht-content-padding / 4) $yacht-content-padding;

        @media (min-width: 500px) and (max-width: 991px) {
            padding-bottom: 3em;
        }
        
        @media (max-width: 500px) {
            padding: ($yacht-content-padding / 2);
            margin-top: -($yacht-content-padding / 2);
            margin-right: -($yacht-content-padding / 2);
            margin-left: -($yacht-content-padding / 2);
        }

        .basic-info {

            .fa-arrow-left {
                margin: 12px 12px 0 0;
            }

            .basic-info-name {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .navbar-toggler {

            &:focus {
                box-shadow: none;
            }

            .navbar-toggler-icon {
                width: auto;
                height: auto;
                background: none;
            }
            
        }

        .mobile-button.navbar {
            margin: 0 !important;
            padding: 0;
        }

        .profile-picture {

            .profile-circle {
                width: 200px;
                height: 200px;
                margin-top: -6em;
                border: 8px solid #fff;
                box-shadow: 0px 0px 30px 10px rgb(0 0 0 / 15%);

                @media  (max-width: 1200px) {
                    width: 170px;
                    height: 170px;
                    margin-top: -7em;
                    border-width: 6px;
                }
                

                .initials {
                    font-size: 7em;
                }
            }
        }

        nav {
            margin-top: -2em !important;

            .navbar-toggler {
                margin: 0 $yacht-content-padding ($yacht-content-padding / 2) $yacht-content-padding;
                
                @media (max-width: 500px) {
                    margin: 0 ($yacht-content-padding / 2) ($yacht-content-padding / 2) ($yacht-content-padding / 2);
                }
            }

            @media (max-width: 1200px) {
                margin-top: .5em !important;
            }

            li {
                
                // &:first-child {
                //     a {
                //         padding-left: 1em !important
                //     }
                // }

                &.active {

                    a { color: $primary; }

                    @media (min-width: 991px) {
                        a:after {
                            content: '';
                            border-style: solid;
                            border-color: transparent;
                            border-bottom-color: $gray-300;
                            border-width: 0 0 30px 30px;
                            height: 0;
                            width: 0;
                            transform: rotate(45deg) translateX(-50%);
                            position: absolute;
                            bottom: -25px;
                            left: 50%;
                            margin-left: -3px;
                        }
                    }
                }

                @media (min-width: 991px) {
                    &:first-child {
                        margin-left: -1em;
                    }
                }

                a {
                    color: $gray-600;
                    position: relative;
                    font-size: 1.1em;
                    padding: .5em $yacht-content-padding !important;
                    
                    &:hover { color: $primary; }
                    
                    @media (min-width: 991px) and (max-width: 1200px) {
                        padding-left: 15px !important;
                    }
                    
                    @media (max-width: 500px) {
                        padding: .5em ($yacht-content-padding / 2) !important;
                    }
                }
            
            }

        }
        
        @media (max-width: 991px) {
            nav {
                margin: 0 -$yacht-content-padding;

                @media (max-width: 500px) {
                    margin: 0 -($yacht-content-padding / 2)
                }

                .navbar-collapse {
                    background: $gray-400;
                }
            }
        }

        @media (max-width: 576px) {
            .crew-member-header-top {
                flex-direction: column;
                
                .basic-info {
                    margin-top: -1em;
                    z-index: 2;

                    h2 {
                        background-color: rgba($gray-300, 0.5);
                        backdrop-filter: blur(4px);
                        padding: 0 5px 0 0;
                    }
                }

                .profile-picture {
                    
                    .profile-circle {
                        float: right;
                        width: 120px;
                        height: 120px;

                        @media (max-width: 480px) {
                            margin-top: -7em;
                            margin-right: -1em;
                            border-width: 4px;
                        }
                    }
                }
            }
        }
    }  
    
    .crew-member-content {
        margin-top: $yacht-content-padding;

        @media (max-width: 500px) {
            margin-top: ($yacht-content-padding / 2)
        }

        .dataTables-filters {
            @media (max-width: 500px) {
                margin-left: 0;
                margin-right: 0;
                padding: ($yacht-content-padding / 2);
            }
        }

        .table-container {
            overflow-y: auto;
            transform: rotateX(180deg);

            & > * {
                transform: rotateX(180deg);
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
                height: 8px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary;
            }

            @media (max-width: 500px) {
                margin-left: -($yacht-content-padding / 2);
                margin-right: -($yacht-content-padding / 2);
                padding: 0;
            }
        }

        .init-culverdocs-update {

            &.mobile-btn {
                display: none;
                background: $primary;
                padding: 5px;
                color: #fff;
                text-decoration: none;
                font-size: .8em;

                @media (max-width: 500px) {
                    display: inline-block;
                    // margin-left: 10px;
                }
            }
        }
    }


}