
.general-content {

    h1 {
        color: #fff;
    }

    .content {
        background: #fff;
        padding: 2em;
        box-shadow: 5px 5px 30px 10px rgb(0 0 0 / 30%);

        &.content-heading {
            margin: 3em 0;

            h1 {
                color: $body-bg;
            }
        }

        &.content-sm {
            max-width: 1000px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

    }

}