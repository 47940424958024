.table-container {
    margin: 0 -2em;
    padding: 0 2em;
    transition: all 500ms ease;

    & > div {
        transition: all 500ms ease;
        opacity: 1;
    }

    &.table-loading {
        filter: blur(5px);
        opacity: .8;
        height: 900px;
        opacity: 0;
        transition: all 500ms ease;
    }

    &.table-refreshing {
        pointer-events: none;
        position: relative;

        & > div {
            opacity: .5;
            transition: all 500ms ease;
        }

        &:before {
            content: '';
            position: absolute;
            top: 40px;
            left: 50%;
            z-index: 2;
            margin-left: -21px;
            @extend .spinner-border;
        }
    }
}


// .datatables-spinner-container {
//     background: rgba(255, 255, 255, 0.7);
//     z-index: 2;
// }

.dataTables_wrapper {
    
    .dataTable thead {
        .sorting_asc,
        .sorting_desc,
        .sorting {
            background-image: none !important;
            position: relative;
            // display: flex;
            // align-items: center;

            &:before {
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                margin-right: .5em;
                color: $secondary;
                opacity: .2;
                content: '\f0dc';
                margin-top: -4px;
                top: 50%;
                // position: absolute;
                // right: 1rem;
                // top: 50%;
                // transform: translateY(-50%);
                right: -5px;
            }

            &:after {
                display: none;
            }
        }

        .sorting_asc,
        .sorting_desc {
            &:before {
                opacity: 1 !important;
                color: $primary;
            }
        }
        
        .sorting_asc {
            &:before {
                content: '\f0de' !important;
            }
        }
        
        .sorting_desc {
            &:before {
                content: '\f0dd' !important;
            }
        }
    }


    table.dataTable {
        border-bottom: 2px solid #dee2e6;
        border-top: 2px solid #dee2e6;
    }

    input {
        // padding: $input-padding-y $input-padding-x !important;
        // background: #fff !important;
        // border: 1px solid $gray-400 !important;
    }

    thead, tbody, tr, td, th {
        padding: 0.75rem !important;
        vertical-align: middle !important;
        border-bottom: 1px solid#dee2e6 !important;
    }
    

    tr:last-child {
        td {
            border-bottom: none !important;
        }
    }

    &.custom-button-location .dt-buttons {
        display: none;
    }

    .dataTables_paginate {

        .paginate_button {
            border: none;

            &.current, &.current:hover {
                background: $primary;
                color: #fff !important;
                border: none;
            }

            &:hover {
                background: rgba($primary, 0.3);
                color: $body-color !important;
                border: none;
            }

        }

    }   
    
    .dataTables_length,
    .dataTables_paginate {
        padding: 1em;
    }

}

.dataTables-filters {
    background: $gray-300;
    padding: 2em 2em;
    margin: 1em -2em;

    ul {
        margin: 0 -1em;
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .column-filter {
        padding: 0 1rem;

        label {
            margin-bottom: .5em;
        }

        select,
        input {
            max-width: 220px;
        }
    }

    .column-filter-search input {
        margin-left: 0 !important;
    }

    p {
        margin: 0;
        font-size: .9em;
        margin-top: 0.5em;
        margin-bottom: -23px;
        color: $gray-600;
    }
}

.dataTables-custom-buttons {

    button {
        background: $primary;
        color: #fff;
        border: none;
        margin-bottom: 0;
        font-size: .9rem;
        /* line-height: 1.5; */
        padding: 0.375rem 0.75rem;
        border-radius: $border-radius;

        &:hover {
            background: lighten($primary, 10%) !important;
            color: #fff !important;
            border: none !important;
        }

        &:focus, &:active {
            border: none !important;
            text-shadow: none !important;
            background: lighten($primary, 10%) !important;
        }
    }

}