.yacht-header {

    @media (max-width: 768px) {
        .yacht-title-container {
            display: block !important;
            width: 100%;
            padding-right: 0;
            
        }
    }

}

.contact-sarnia {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: block;
    transition: all 500ms ease;
    @media (max-width: 500px) {
        bottom: 1rem;
        right: 1rem;
    }

    &:hover {
        transform: scale(1.1);
    }

    .icon-circle {
        background: $primary;
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        border-radius: 50%;
        color: #fff;
        
        @media (max-width: 500px) {
            width: 3.5rem;
            height: 3.5rem;
        }
        
    }

    .text {
        line-height: 1.2;
        margin-left: .5em;
    }
}