header {
    background: #fff;
    padding: 1em;
    border-bottom: 3px solid $primary;
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 30%);

    .logo {
        display: flex;
        align-items: center;

        img {
            @media (max-width: 900px) {
                width: 150px;
            }
        }

        .portal-nav {
            margin-left: 2em;
        
            @media (max-width: 768px) {
                display: none;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                @media (max-width: 1000px) {
                    font-size: .9em;
                }

                li {
                    float: left;

                    a {
                        padding: .5em 1em;
                        display: block;
                        text-decoration: none;
                        background: $gray-100;

                        &:hover:not(.active) {
                            background: $gray-200;
                        }

                        &.active {
                            background: $gray-400;
                        }
                    }
                }
            }
        }
    }

    .search {
        position: relative;
        max-width: 350px;
        width: 100%;

        @media (max-width: 1000px) {
            max-width: 200px;
        }

        .spinner {
            position: absolute;
            right: 15px;
            z-index: 8;
            background: #fff;
            top: 7px;
            width: 20px;
            display: none;
        }

        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            opacity: .5;
            z-index: 5;
        }
    }

    .quick-access {
        margin-right: 1.5em;
        
        a {
            color: $body-color;
            text-decoration: none;

            i {
                color: $text-muted;
            }
        }

        .dropdown-menu {
            padding-top: 0;
            padding-bottom: 0;
            
            .inner {
                min-height: 50px;
                max-height: 250px;
                overflow-y: auto;
                padding: .5em 0;
            }

            .yacht-image {
                width: 35px;
                height: 35px;
                display: inline-block;
                border-radius: 50%;
                background-size: cover;
                margin-right: .75em;
                background-position: center;
            }

        }
    }

    .profile {
        // .contact-sarnia {
        //     border-right: 2px solid $border-color;
        //     padding-right: 1em;
        //     margin-right: 1em;
        //     margin-left: 1em;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;

        //     @media (max-width: 576px) {
        //         margin-right: .5em;
        //         padding-right: .5em;
        //     }

        //     &:hover {
        //         .icon-circle {
        //             background: $primary;
        //             color: #fff;
        //         }
        //     }

        //     .text {
        //         line-height: 1.2;
        //         margin-left: .5em;
        //     }
        // }

        .welcome {
            line-height: 1;
            text-align: right;
        }

        #profile-dropdown {
            text-decoration: none;
        }

        .profile-circle {

            @media (max-width: 576px) {
                margin-left: 0;
            }

            .dropdown-arrow {
                position: absolute;
                bottom: -6px;
                left: 0;
                background: $blue;
                color: #fff;
                border-radius: 50%;
                width: 16px;
                line-height: 18px;
                height: 16px;
                font-size: .5em;
                vertical-align: middle;
            }
            
        }
    }
}