body[id^="view-yacht"] {

    .stats-container {
        margin-top: -$grid-gutter-width;

        .stat-box-container {
            background: $gray-200;
            padding: 1em;
            margin-top: $grid-gutter-width;

            h4 a {
                text-decoration: none;

                &:hover {
                    color: $body-color;
                    text-decoration: underline;
                }
            }
        }
    }

    .no-yacht-location {
        padding: 4em;
        text-align: center;

        i {
            font-size: 3em;
        }
    }

    .yacht-location-map-container {
        margin: 0 -2em -2em -2em;
    }

    .yacht-location-map-inner {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;

        #yacht-map {
            height: 400px;
            
            .yacht-location-marker {
                background: #fff;
                padding: 4px;
                display: block;
                border-radius: 50%;
                position: relative;
                z-index: 2;
                top: -40px;
                left: -4px;
                box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);

                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 15px 0 15px;
                    border-color: #fff transparent transparent transparent;
                    bottom: -10px;
                    left: 14px;
                    position: absolute;
                    z-index: 1;
                }

                .yacht-image {
                    width: 50px;
                    height: 50px;
                    background-size: cover;
                    display: block;
                    border-radius: 50%;
                    position: relative;
                    z-index: 2;
                    background-position: center;
                }
            }
        }
        
        .yacht-location-stats {
            position: absolute;
            top: 0;
            left: 0;
            margin: 2em;
            padding: 1em;
            background: #fff;
            // background: rgba($secondary, 0.95);
            // color: #fff;
            box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);

            p {
                margin: 0;
            }
        }

        
    }

    #seven-day-timeline-container {
        margin: 2em -2em 0 -2em;
        min-height: 180px;
        flex-direction: column;
        display: flex;

        #seven-day-timeline {
            flex: 1;

            &.no-events {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
            }
        }

        .header {
            padding: 0 2em 0 2em;
        }

        .glide__track {
            padding: 2em;
        }

        .timeline-day {
            background: $gray-200;
            margin-right: 1em;
            padding: 1.5em;

            &.today {
                border: 1px solid $primary;
            }

            .timeline-day-events {
                // min-height: 140px;
                max-height: 440px;
                overflow-y: auto;

                .schedule-popup {
                    margin-bottom: 1em;
                    padding-bottom: 1em;
                    border-bottom: 1px solid $border-color;

                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }

        .glide__arrows {
            button {
                background: $primary;
                border: none;
                border-radius: 0px;
                padding: .75em;
               

                &.glide__arrow--right {
                    right: 0;
                    border-radius: $border-radius 0 0 $border-radius;
                }

                &.glide__arrow--left {
                    left: 0;
                    border-radius: 0 $border-radius $border-radius 0;
                }

                &.glide__arrow--disable {
                    opacity: 0.33;
                }
            }
        }
    }

    .qualifications-table,
    .documents-table {
        .OnBoard {
            display: none;
        }
    }

}