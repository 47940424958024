@font-face {
    font-family: "Spartan";
    src: url('/fonts/spartan/Spartan-Regular.eot');
    src: url('/fonts/spartan/Spartan-Regular.eot') format('embedded-opentype'),
         url('/fonts/spartan/Spartan-Regular.woff2') format('woff2'),
         url('/fonts/spartan/Spartan-Regular.woff') format('woff'),
         url('/fonts/spartan/Spartan-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Spartan";
    src: url('/fonts/spartan/Spartan-Bold.eot');
    src: url('/fonts/spartan/Spartan-Bold.eot') format('embedded-opentype'),
         url('/fonts/spartan/Spartan-Bold.woff2') format('woff2'),
         url('/fonts/spartan/Spartan-Bold.woff') format('woff'),
         url('/fonts/spartan/Spartan-Bold.ttf') format('truetype');
      font-style: bold;
      font-style: normal;
}
  

@font-face {
    font-family: "Spartan";
    src: url('/fonts/spartan/Spartan-Regular.eot');
    src: url('/fonts/spartan/Spartan-Regular.eot') format('embedded-opentype'),
         url('/fonts/spartan/Spartan-Regular.woff2') format('woff2'),
         url('/fonts/spartan/Spartan-Regular.woff') format('woff'),
         url('/fonts/spartan/Spartan-Regular.ttf') format('truetype');
    font-style: italic;
}
  

h1, h2, h3, h4, h5 {
    font-family: "Spartan", Georgia, Times, serif;   
}

.text-extra-small {
    font-size: .7em;
}