.group:after {
    content: "";
    display: table;
    clear: both;
}

.disabled-link {
    pointer-events: none;
    opacity: .6;
}

a[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: .5;
}

.bg-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    filter: blur(5px);
    opacity: .5;

    &:after {
        content: '';
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(25,25,55,0) 40%, rgba(25,25,55,0.5) 60%, rgba(25,25,55,0) 80%, rgba(45,48,71,1) 100%);
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
    }

    &.yacht {
        opacity: .2
    }
}

.modal-backdrop {
    z-index: 9999;
}

.modal {
    z-index: 99999;
}

.cursor-pointer {
    cursor: pointer;
}