#viewer-modal {
    
    .modal-dialog {
        align-items: stretch;
    }

    &.image-viewer {

        .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 50px;
            height: 100%;

            img {
                max-width: 100%;
                width: auto;
            }
        }
    }

    .modal-content {
        min-height: 500px;
        
        .modal-body {
            padding: 0;
        }
    }

    .file-heading {
        background: $secondary;
        color: #fff;
        padding: 1em;
        margin: -1px -1px 0 -1px;
        position: relative;
        z-index: 2;

        a {
            float: right;
            color: $primary;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .spinner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        z-index: 1;

        span {
            width: 3em;
            height: 3em;
        }
    }

    #pdf-viewer {
        height: 100%;
    }


}