.modal {

    .floating-modal-close {
        position: absolute;
        right: -12.5px;
        top: -12.5px;
        background-color: $primary;
        color: #fff;
        z-index: 3;
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            outline: 2px solid darken($primary, 30%);
        }
    }

    .modal-dialog {
        @media (max-width: 575px) {
            margin: 1.5em;

            .modal-body, .modal-footer {
                padding: 1em;
            }
        }
    }

}

#culverdocs-iframe-modal {
    .modal-body button {
        position: absolute;
        right: -1rem;
        top: -1rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: $primary;
        border: none;
        color: #fff;
        padding: 0;
        font-size: 1.5em;
    }

    .modal-footer {
        display: none;
    }
}