/**
 * Abstracts
 * Sass helpers
 */ 
 @import 'abstracts/variables';

 /**
  * Vendor
  */ 
 @import '~bootstrap/scss/bootstrap';
 
 @import '~@fortawesome/fontawesome-pro/css/fontawesome';
 @import '~@fortawesome/fontawesome-pro/css/brands';
 @import '~@fortawesome/fontawesome-pro/css/duotone';
 @import '~@fortawesome/fontawesome-pro/css/light';
 @import '~@fortawesome/fontawesome-pro/css/regular';
 @import '~@fortawesome/fontawesome-pro/css/solid';
 @import '~@fortawesome/fontawesome-pro/css/v4-shims';
 @import "~@glidejs/glide/src/assets/sass/glide.core";
 @import "~@glidejs/glide/src/assets/sass/glide.theme";
 @import 'datatables.net-dt';
 @import 'datatables.net-buttons-dt';
 @import 'noty/src/noty';
 @import 'noty/src/themes/bootstrap-v4';
 @import 'tui-calendar/dist/tui-calendar';
 @import 'tui-date-picker/dist/tui-date-picker';
 @import 'tui-time-picker/dist/tui-time-picker';
 @import 'tippy.js/dist/tippy';
 @import 'tippy.js/animations/scale';
 @import 'trumbowyg/dist/ui/trumbowyg';
 
 /**
  * Components
  */
 @import 'components/buttons';
 @import 'components/typography';
 @import 'components/sarnia-heading';
 @import 'components/glidejs';
 @import 'components/crew-digest';
 @import 'components/icon-circle';
 @import 'components/dropdowns';
 @import 'components/stat-box';
 @import 'components/profile-circle';
 @import 'components/datatables';
 @import 'components/form';
 @import 'components/global-search';
 @import 'components/general-content';
 @import 'components/download-sharepoint-file';
 @import 'components/modal';
 @import 'components/popup';
  
 /**
  * Layout
  */ 
 @import 'layout/general';
 @import 'layout/header';
 @import 'layout/yacht';
 @import 'layout/crew-portal';
 
 /**
  * App Views 
  */
 @import 'views/login';
 @import 'views/yachts';
 @import 'views/yacht';
 @import 'views/crew-member';
 @import 'views/calendar';
 @import 'views/payroll';