.icon-circle {

    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    background: $blue;
    font-size: $font-size-base;
    color: #fff;
    font-family: $font-family-sans-serif;

    &.icon-circle-small {
        width: 20px;
        height: 20px;
        font-size: .7rem;
    }

    &.icon-circle-medium {
        width: 30px;
        height: 30px;
        font-size: .9rem;
    }

    &.icon-circle-white {
        background: #fff;
        color: $body-color;
    }

    &.icon-circle-light-grey {
        background: $gray-300;
        color: $gray-600;
    }

    &.icon-circle-darker-grey {
        background: $gray-500;
        color: $gray-700;
    }

    &.icon-circle-primary {
        background: $primary;
        color: #fff;
    }

    &.icon-circle-color-success {
        background: $success;
        color: #fff;
    }

    span {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        height: 100%;
    }

    &.icon-circle-danger {
        background: $danger;
    }

    &.icon-circle-warning {
        background: $warning;
    }
    
    i {
        color: #fff;
    }

}