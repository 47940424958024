.crew-digest {
    margin: 0 0 0 .9em;
    list-style: none;
    padding: 0;

    li {
        float: left;
        margin-left: -15px;
        
        .profile-circle {
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
            position: relative;
        }

        &.remaining-crew {
            position: relative;
            
            span {
                background: $blue;
                width: 40px;
                height: 40px;
                display: block;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
                
            }
        }
    }
}