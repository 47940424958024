#view-yachts {

    .yacht-slider {
        height: 100%;

        &.yacht-count-1 {
            .glide__arrows {
                display: none;
            }
        }

        .glide__track,
        .glide__slides {
            height: 100%;
        }

        .glide__arrows {
            button {
                background: $primary;
                border: none;
                border-radius: 0px;
                padding: 2em .75em;
               

                &.glide__arrow--right {
                    right: 0;
                    border-radius: $border-radius 0 0 $border-radius;
                }

                &.glide__arrow--left {
                    left: 0;
                    border-radius: 0 $border-radius $border-radius 0;
                }
            }
        }
        
        h1, h4 {
            color: #fff;
        }

        .yacht-item {
            position: relative;
            padding: 3em;
            overflow: hidden;
            height: 100%;
            display: block;

            a {
                text-decoration: none;
            }

            .yacht-image {
                position: absolute;
                opacity: .2;
                width: 110%;
                height: 110%;
                display: block;
                background-size: cover;
                background-position: center;
                z-index: -1;
                filter: blur(2px);
                top: -10px;
                left: -10px;
            }

            .yacht-item-top {
                max-width: 600px;
                margin: 0 auto;
                
                .image-circle {
                    width: 125px;
                    height: 125px;
                    background-size: cover;
                    background-position: center;
                    display: block;
                    border-radius: 50%;
                }
            }

            .yacht-item-stats {
                margin-top: $grid-gutter-width;
                max-width: 600px;
                margin: 0 auto;
            }
        }

    }

}