#yacht-layout {

    
    &:not(.yacht) {
        // .yacht-main {
        //     margin-top: -10em;
        // }
        
        // .yacht-title {
        //     margin-top: -9em;
        // }

        .yacht-header .yacht-header-image {
            height: 170px;
            transition: all 500ms ease;
            
            &:hover {
                height: 300px;
                transition: all 500ms ease;
                transition-delay: 300ms;
            }
        }
    }
    
    .open-yacht-settings {
        position: relative;
        top: -5px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        opacity: .6;
        margin-left: .5em;
        font-family: $font-family-sans-serif;

        &:hover {
            opacity: 1;
        }
    }

    .yacht-header {
        margin-top: 1.5em;

        h1, h3 {
            color: #fff;
        }

        .yacht-header-image {
            width: 100%;
            height: 300px;
            display: block;
            background-size: cover;
            background-position: center;
        }       

    }

    .yacht-main {
        margin: 0;

        & > div {
            padding: 0;
        }
        
        .yacht-navigation {
            min-width: 220px;
            margin-top: 1.5em;

            nav {
                background: rgba(255, 255, 255, 0.2);
                
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                a {
                    color: #fff;
                    padding: 1em;
                    display: block;
                    font-size: 1.1em;
                    transition: all 250ms ease;
                    text-decoration: none;

                    i {
                        width: 20px;
                        text-align: center;
                    }

                    &.active {
                        background: $primary;
                    }

                    &:not(.active):hover {
                        background: rgba($primary, 0.3);
                        transition: all 250ms ease;
                        text-decoration: none;
                    }

                    .icon-circle {
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }

        .action-container {
            margin: 1em;

            a {
                display: block;
            }
        }

        .yacht-content-container {
            width: 100%;

            .yacht-content {
                background: #fff;
                padding: $yacht-content-padding;
                box-shadow: 5px 5px 30px 10px rgb(0 0 0 / 30%);
                min-height: 600px;
                position: relative;
                max-width: 1256px;
                width: 100%;
            }
        }
        

    }

}