#view-yacht-payroll-batch {

    .open-payroll-line {
        cursor: pointer;
        transition: all 250ms ease;

        &:hover,
        &.active {
            background: $gray-200;
        }
    }

    .line-details {
        background: $gray-100;
        padding: 0 !important;
        

        .active table {
            box-shadow: $box-shadow-lg;
        }

        table {
            transition: all 250ms ease;
            box-shadow: none;
        }
    }

}