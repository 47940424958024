.global-search-results {
    position: absolute;
    width: 100%;
    background: #fff;
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 40%);
    top: 50px;
    z-index: 9999;

    h5 {
        padding: .5em 1em;
        margin: 0;
        background: $gray-200;
        border-bottom: 1px solid $gray-400;
    }

    ul {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $gray-400;
        list-style: none;

        li a {
            display: block;
            padding: .5em 1em;
        }
    }
}