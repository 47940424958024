.btn-primary {
    color: #fff;

    &:hover,
    &:focus {
        color: #fff;
    }

    &.disabled {
        opacity: .5 !important;
    }
}