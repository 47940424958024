.profile-circle {
    background: $gray-400;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    text-align: center;
    margin-left: 1em;
    position: relative;
    background-size: cover;
    background-position: center;
    // filter: grayscale(100%);
    border: 3px solid #fff;
    box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);

    &.lazy-load-employee-profile {
        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 9px;
            @extend .spinner-border;
            @extend .spinner-border-sm;
        }
    } 
    
    &.image-loaded {
        .initials {
            opacity: 0;
        }
    }

    .initials {
        font-size: 1.4em;
        color: #000;
        opacity: .15;
        top: 1px;
        position: relative;
    }

    &.profile-circle-enlarged {
        .initials {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            left: 50%;
        }
    }

}