.sarnia-heading {
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 30px;
        width: 4px;
        height: 25%;
        background-color: #F19F0E;
        opacity: 1;
        z-index: -1;
        transform: translate(-50%, 0) rotate(-30deg);
        transform-origin: center center;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 8px;
        left: 95px;
        width: 4px;
        height: 25%;
        background-color: #F19F0E;
        opacity: 1;
        z-index: -1;
        transform: translate(-50%, 0) rotate(-30deg);
        transform-origin: center center;
    }
}