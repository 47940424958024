#view-yacht-calendar, #view-crew-portal-calendar {
    
    #menu {
        margin-bottom: 1em;

        .dropdown-item {
            cursor: pointer;
        }

        .calendar-views {
            margin-left: 2em;

            @media (max-width: 768px) {
                margin-left: 0;
            }

            a[data-action].active {
                font-weight: bold;
                color: $primary;
            }

            a {
                margin: 0 1em 0 0;
            }
        }

        input[type=checkbox].tui-full-calendar-checkbox-square:checked + span {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbElEQVQokb3SMQ6AIAwF0I9DF+5/gX+k3sWF5TsZCqIYEm3Shf6XEGgCICzUtoK+h2YZJC/nemqzLHeXJJGMsxoiKbM8RO7ezBBRDExQhX1wgtqrRjxBLYx4gpRO3T8/AJSy337REL6p/1fuAFp1nO1ywCeVAAAAAElFTkSuQmCC);
        }
    }

    #calendar {
        margin: 0 -2em -2em -2em;
        position: relative;
        
        .tui-full-calendar-holiday-sun,
        .tui-full-calendar-weekday-grid-header span  {
            color: $body-bg !important;
        }

        .tui-full-calendar-month {
            .tui-full-calendar-extra-date,
            .schedule-outside-current-month {
                opacity: .5;
            }
        }

        .tui-full-calendar-dayname-leftmargin {
            margin-left: 0 !important;
        }

        .tui-full-calendar-dayname {
            top: 0;
        }

        .tui-full-calendar-near-month-day {
            top: 0;
            .tui-full-calendar-weekday-grid-date {
                font-weight: bold;
            }
        }

        .tui-full-calendar-today {
            position: relative;

            // Hacky, border not possible on primary selector as bottom border not visible
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 99%;
                border: 1px solid $primary;
                display: block;
                top: 0;
                left: 0;
            }
        }

        &.calendar-loading {
            .tui-full-calendar-layout {
                opacity: .5;
                transition: all 500ms ease;
                filter: blur(2px);
            }

            .calendar-loading-spinner {
                opacity: 1;
                transition: all 500ms ease;
                z-index: 999;
            }
        }

        .tui-full-calendar-layout {
            opacity: 1;
            transition: all 500ms ease;
            filter: blur(0px);
        }

        .calendar-loading-spinner {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: -1;
            transition: all 500ms ease;
        }

        .tui-full-calendar-left {
            display: none;
        }

        // When in month view, clicking on a day displays this blue border 'guide' not sure as to the purpose hence hiding as it's unnecessary
        .tui-full-calendar-month-creation-guide,
        .tui-full-calendar-month-guide-block {
            display: none;
        }

        .tui-full-calendar-weekday-grid-date-decorator {
            background-color: $primary;
        }

    }


    div[data-tippy-root] {
        min-width: 300px;
        
        .tippy-box {
            background: #fff;
            color: #000;
            padding: 1em;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
        }

        .tippy-arrow {
            color: #fff;
        }
    }

}

#calendar-key {
    .key-circle {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .counter {
            position: absolute;
            top: -7px;
            background: #fff;
            line-height: .5;
            padding: 3px;
            border-radius: 50%;
            font-size: .7em;
            border: 1px solid;
        }
    }
}

.schedule-contract-bg {
    background-color: #6cb58a !important;
}

.schedule-contract-text {
    color: #6cb58a !important;
}

.schedule-personal-bg {
    background-color: #6c79b5 !important;
}

.schedule-personal-text {
    color: #6c79b5 !important;
}

.schedule-qualification-bg {
    background-color: #a1b56c !important;
}

.schedule-qualification-text {
    color: #a1b56c !important;
}

.schedule-qualification-border {
    border-color: #a1b56c !important;
}

.schedule-document-bg {
    background-color: #b5936c !important;
}

.schedule-document-text {
    color: #b5936c !important;
}

.schedule-document-border {
    border-color: #b5936c !important;
}

.schedule-certificate-bg {
    background-color: #6caeb5 !important;
}

.schedule-certificate-text {
    color: #6caeb5 !important;
}

.schedule-certificate-border {
    border-color: #6caeb5 !important;
}

.schedule-insurance-bg {
    background-color: #b56c6c !important;
}

.schedule-insurance-text {
    color: #b56c6c !important;
}

.schedule-insurance-border {
    border-color: #b56c6c !important;
}