.swal2-container {
    z-index: 999999 !important;

    .swal2-popup {
        margin: 2.5rem 0;
        color: $body-color;

        .swal2-title {
            font-family: $font-family-sans-serif;
            font-size: 1.5em;
        }

        .swal2-actions {

            .swal2-confirm {
                background-color: $primary;
                color: $secondary;
            }

            .swal2-deny {
                background-color: $secondary;
            }

        }
    }

    .swal2-html-container {
        text-align: left;
        padding: 1rem
    }

    .swal2-close {
        &:hover {
            color: $primary;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

}
