.stat-box {
    background: rgba($secondary, 0.75);
    padding: 1.25em;
    margin-top: $grid-gutter-width;
    display: block;
    color: #fff;
    transition: all 250ms ease;
    border-top: 5px solid $secondary;
    text-decoration: none;

    &.solid-secondary {
        background: $secondary;

        &:hover {
            color: #fff;
        }
    }

    &.light-grey {
        background: $gray-200;
        color: $body-color;

        &:hover {
            color: $body-color
        }
    }

    &:hover {
        transform: scale(1.03);
        transition: all 250ms ease;
        color: #fff;
        text-decoration: none;
    }
    
    &.stat-success {
        border-top-color: $blue;
    }

    &.stat-danger {
        border-top-color: $danger;
    }

    &.stat-warning {
        border-top-color: $warning;
    }

    .stat-top {
        .fa-arrow-right {
            opacity: .5;
        }

        @media (max-width: 991px) {
            div {
                .icon-circle {
                    // display: block !important;
                }

                h5 {
                    // margin-top: .5em;
                    // margin-left: 0 !important;
                }
            }
        }
    }

    .stat-bottom {
        ul {
            margin: 1em -.5em 0 -.5em;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin: 0 .5em
            }
        }
    }
}
