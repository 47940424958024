#view-login, #view-two-factor-login, #view-set-password, #view-forgot-password {
    position: relative;
    min-height: 100vh;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background-image: url('/img/logo-bg.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: blur(5px);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
    }

    .login-container {
        margin: 3em 0;
    }

    .login-tabs {
        font-size: 1.1em;
        margin-left: 2em;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            
            li {
                float: left
            }

            a {
                padding: .5em 1em;
                color: #fff;
                text-decoration: none;
                display: block;
                transition: all 500ms ease;
                background: rgba(255, 255, 255, 0.2);

                &:hover {
                    background: rgba(255, 255, 255, 0.5);
                    transition: all 500ms ease;
                }

                &.active {
                    background: #fff;
                    color: $body-color;
                }
            }
        }
    }
    
    .login-inner {
        width: 400px;
        padding: 2em;
        display: block;
        text-align: left;

        input[name="password"] {
            margin-top: -1px;
        }

        input:not([type="submit"]) {
            padding: .85em;
        }

    }

    
    .otc {
        position: relative;
        width: 320px;
        margin: 0 auto;
        fieldset {
            border: 0;
            padding: 0;
            margin: 0;
            div {
            display: flex;
            align-items: center;
            }
        }
        label {
            border: 0 !important;
            clip: rect(1px, 1px, 1px, 1px) !important;
            clip-path: inset(50%) !important;
            height: 1px !important;
            margin: -1px !important;
            overflow: hidden !important;
            padding: 0 !important;
            position: absolute !important;
            width: 1px !important;
            white-space: nowrap !important;
        }
        div::before {
            content: '';
            height: 2px;
            width: 24px;
            margin: 0 .25em;
            order: 1;
            background: $primary;
            visibility: hidden;
        }
        input[type="number"] {
            width: .82em;
            line-height: 1;
            margin: .1em;
            padding: 8px 0 4px;
            font-size: 2.65em;
            text-align: center;
            appearance: textfield;
            border: 1px solid $input-border-color;
            color: $body-color;
            border-radius: 4px;
            &:focus-visible {
            border-color: $primary !important;
            outline-color: $primary !important;
            }
        }
        
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .two-factor-tabs {
        font-size: 1.2em;
        
        button {
            border: none;

            &.active {
                color: #fff;
            }
        }
    }

    .send-one-time-code {
        font-size: 1.2em;
    }
        
}

#view-two-factor-login {

    .login-inner {
        max-width: 500px;
        width: 100%;
    }

}